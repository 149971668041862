import { ErrorMessage, Field, Form, Formik } from "formik"
import React from "react"
import { Helmet } from "react-helmet"
import CallNow from "../components/callNow"
import GoogleMap from "../components/googleMap"
import Layout from "../components/layout.js"
import "./../styles/kontakt.scss"

const AboutPage = () => {
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  return (
    <Layout>
      <Helmet>
        <title>Wulkanizacja Tychy KostaAuto - Kontakt</title>

        <link rel="canonical" href="https://oponytychy.pl/kontakt" />
        <meta
          name="description"
          content="Formularz kontaktowy Serwisu KostaAuto. Mapa dojazdu - wskazówki jak do nas trafić. Telefon, mail, adres wszystko czego potrzebujesz żeby trafić do naszego serwisu wymiany opon w Tychach! Zadzwoń i przyjedź na wymianę opon lub na naprawę opony!"
        />
        <meta
          name="keywords"
          content="zamiana opon, zmiana opon Tychy, serwis opon, serwis opon Tychy"
        />
      </Helmet>
      <GoogleMap />
      <CallNow />
      <div className="main">
        <div className="container_12">
          <div className="grid_8">
            <div>
              <div className="indent-right16 indent-top1-1">
                <h2 className="p3-1">
                  <span>Kontakt:</span>
                </h2>

                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    message: "",
                  }}
                  onSubmit={(values, actions) => {
                    fetch("/", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                      body: encode({
                        "form-name": "contact-form",
                        ...values,
                      }),
                    })
                      .then(() => {
                        const contactForm =
                          document.getElementById("contact-form")
                        contactForm.classList.add("success-form")
                        actions.resetForm()
                        setTimeout(() => {
                          contactForm.classList.remove("success")
                        }, 7000)
                      })
                      .catch(() => {
                        alert("Error")
                      })
                      .finally(() => actions.setSubmitting(false))
                  }}
                  validate={values => {
                    const emailRegex =
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                    const phoneRegex =
                      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

                    const errors = {}
                    if (!values.name) {
                      errors.name = "*To pole jest konieczne."
                    }
                    if (!emailRegex.test(values.email)) {
                      errors.email = "*To nie jest poprawny adres email."
                    }
                    if (!values.email) {
                      errors.email = "*To pole jest konieczne."
                    }
                    if (!phoneRegex.test(values.phone)) {
                      errors.phone = "*To nie jest poprawny numer telefonu."
                    }
                    if (!values.phone) {
                      errors.phone = "*To pole jest konieczne."
                    }
                    if (!values.message) {
                      errors.message = "*To pole jest konieczne."
                    }
                    return errors
                  }}
                >
                  {() => (
                    <Form
                      id="contact-form"
                      name="contact-form"
                      data-netlify={true}
                    >
                      <div className="success">
                        Wiadomość wysłana!
                        <br />
                        <strong>
                          Odezwiemy się jak tylko będzie to możliwe.
                        </strong>
                      </div>

                      <label htmlFor="name">Nazwisko: </label>
                      <Field name="name" id="name" />
                      <ErrorMessage
                        name="name"
                        className="empty"
                        component="div"
                      />

                      <label htmlFor="email">Email: </label>
                      <Field name="email" id="email" />
                      <ErrorMessage
                        name="email"
                        className="empty"
                        component="div"
                      />

                      <label htmlFor="phone">Telefon: </label>
                      <Field name="phone" id="phone" />
                      <ErrorMessage
                        name="phone"
                        className="empty"
                        component="div"
                      />

                      <label htmlFor="message">Wiadomość: </label>
                      <Field name="message" id="message" component="textarea" />
                      <ErrorMessage
                        name="message"
                        className="empty"
                        component="div"
                      />

                      <div className="buttons-wrapper">
                        <button type="reset">Wyczyść</button>
                        <button type="submit" disabled={Formik.isSubmitting}>
                          Wyślij
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="grid_4">
            <div className="box1 indents2">
              <h2 className="color-2 p3-1">
                {" "}
                <span>
                  <strong>Jak </strong>dojechać?
                </span>
              </h2>

              <div>Kieruj się w stronę stacji kolejowej TYCHY - ŻWAKÓW.</div>
              <span>
                Po prawej stronie ulicy Myśliwskiej, 300m przed przejazdem
                kolejowym znajduje się siedziba naszej firmy.
              </span>
              <br />
              <br />

              <div id="" className="vcard">
                <span className="fn n">
                  <span className="given-name">Kosta Auto</span>
                </span>

                <div className="adr">
                  <div>Myśliwska 140</div>
                  <span> Tychy, 43-100</span>
                </div>

                <div>
                  <p>
                    e-mail:{" "}
                    <a
                      aria-label="link do wysyłania emaila w osobym oknie"
                      href="mailto:biuro@oponytychy.pl"
                    >
                      <strong>biuro@oponytychy.pl</strong>
                    </a>
                  </p>

                  <br />
                  <br />

                  <div>Godziny otwarcia:</div>
                  <div class="color-2">Poniedziałek - Piątek 16 - 20 </div>
                  <div class="color-2">Sobota 8 - 12 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
